import { useEffect, useState } from 'react'
import * as S from './Page.styled'
import { ProductPrint } from 'components'
import { camelToSnakeCase } from 'utils'
import isEven from 'utils/global/isEven'

export default ({
  page,
  secondPage,
  theme,
  themeLayout,
  index,
  pdfRender,
  proof,
  isPDF,
  ...props
}) => {
  const [insert, setInsert] = useState([])
  const [isPMRegisterBook, setIsPMRegisterBook] = useState(false)

  const createInsertArray = () => {
    const array = Array.apply(
      null,
      Array(page.pageProperties.pageColumns * page.pageProperties.pageRows),
    ).map((item) => page)
    secondPage && array.push(secondPage)
    setInsert(array)
  }

  useEffect(() => {
    createInsertArray()
    //eslint-disable-next-line
  }, [page])

  useEffect(() => {
    const { collection, product, layout } = page.pageProperties
    collection === 'Precious Memories' &&
      product === 'Register Book' &&
      layout === 'Full Bleed' &&
      setIsPMRegisterBook(true)
    //eslint-disable-next-line
  }, [page])

  const environment = isPDF ? 'pdf' : 'preview'

  return (
    <S.PagePrint
      className="pageItself"
      wrap="wrap"
      key={`${page.pageProperties.pageSetId}+${index}`}
      height={page.pageProperties.pageSize.height}
      width={page.pageProperties.pageSize.width}
      padding={page.pageProperties.pageStartPoints}
      {...props}
    >
      {insert.length > 0 &&
        insert.map((printArea, i) => (
          <ProductPrint
            key={i}
            className="pageContent"
            theme={theme}
            themeLayout={themeLayout}
            paperType={page.pageProperties.paperType}
            page={printArea}
            index={i}
            pdfRender={pdfRender || undefined}
            isPMRegisterBook={isPMRegisterBook}
            isEven={isEven(page.pageProperties.pageNumber)}
            padding={page.pageProperties.pageStartPoints}
            backgroundOpactiy={
              camelToSnakeCase(page?.product?.themeLayout)?.background_opacity
            }
            isProof={proof}
            environment={environment}
            pageTwo={
              page?.pageProperties?.pageNumber >= 2 &&
              !page?.pageProperties?.product?.includes('Register')
            }
            {...props}
          />
        ))}
    </S.PagePrint>
  )
}
