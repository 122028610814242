import { useState } from 'react'
import {
  getCaseAsGuest,
  uploadCaseImageAsGuest as uploadCaseImageAsGuestApi,
} from 'api/cases'
import { defaultPlaceholder, avatarPlaceholder } from 'assets'
import {
  getCaseMemorialsAsGuest,
  createCaseMemorialAsGuest as createCaseMemorialAsGuestApi,
} from 'api/caseMemorials'
import { useSetToast } from 'hooks'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default (location = '') => {
  const handleError = useErrorHandler('useFamilyUpload')
  const [, , token, caseId] = location?.split('/')
  const [_case, setCase] = useState({})
  const [memorials, setMemorials] = useState([])
  const [memorial, setMemorial] = useState('')
  const [memorialError, setMemorialError] = useState('')
  const [submitter, setSubmitter] = useState('')
  const [submitterError, setSubmitterError] = useState('')
  const [loading, setLoading] = useState(false)
  const setToast = useSetToast()

  const getCase = async () => {
    try {
      const { data: caseData } = await getCaseAsGuest(token)
      if (caseData) setCase(caseData)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const getMemorials = async () => {
    try {
      const { data: memorials } = await getCaseMemorialsAsGuest(token)
      if (memorials) setMemorials(memorials)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const createCaseMemorialAsGuest = async () => {
    setMemorialError(memorial ? '' : 'Memorial is required')
    setSubmitterError(submitter ? '' : 'Shared by is required')
    if (!memorial || !submitter) return

    try {
      setMemorialError('')
      setSubmitterError('')

      const payload = {
        case_id: caseId,
        memorial,
        submitter_name: submitter,
      }

      const { data } = await createCaseMemorialAsGuestApi(token, payload)

      data
        ? setToast({ text: 'Memorial shared!' })
        : setToast({ text: 'Memorial upload failed.' })

      return data
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const uploadCaseImagesAsGuest = async (filesArray) => {
    Promise.all(filesArray.map((file) => uploadCaseImageAsGuest(file)))
  }

  const uploadCaseImageAsGuest = async (file) => {
    try {
      setLoading(true)
      let fd = new FormData()
      fd.append('file', file, 'blob')

      const { data } = await uploadCaseImageAsGuestApi(token, fd)

      if (data) {
        getCase()
        setLoading(false)
        setToast({ text: 'Image Uploaded!' })
      } else {
        setToast({ text: 'Image upload failed.' })
      }
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  return {
    token,
    _case,
    getCase,
    memorials,
    memorial,
    setMemorial,
    memorialError,
    getMemorials,
    submitter,
    setSubmitter,
    submitterError,
    createCaseMemorialAsGuest,
    uploadCaseImagesAsGuest,
    loading,
    defaultPlaceholder,
    avatarPlaceholder,
  }
}
