import dynamicTextUtilities from './dynamicTextUtilities'
import preserveStyling from 'utils/tinyMCE/updateDynamicText/preserveStyling'
import { load } from 'cheerio'
import preserveItalicsAndBolds from 'utils/tinyMCE/updateDynamicText/preserveItalicsAndBolds'
import formatDate from 'utils/dates/formatDate'
import preserveMceSpanFontStyle from 'utils/tinyMCE/updateDynamicText/preserveMceSpanFontStyle'

export default (dynamicTextProps) => {
  const { type } = dynamicTextProps.elementProps
  if (type === 'span') handleSimpleCaseDetail(dynamicTextProps)
  if (type === 'div') handleCaseDetailBlock(dynamicTextProps)
}

const handleSimpleCaseDetail = (dynamicTextProps) => {
  const { elementProps, editorProps } = dynamicTextProps
  const { element } = elementProps
  const { dateFormat } = editorProps

  const { getCaseDetail, updateDeepestSpanText } =
    dynamicTextUtilities(dynamicTextProps)

  const { value, type } = getCaseDetail(element)

  let updatedValue = value
  if (type === 'empty') updatedValue = '&nbsp;'
  if (type === 'date') updatedValue = formatDate(value, dateFormat)

  updateDeepestSpanText(element, updatedValue)
}

const handleCaseDetailBlock = (dynamicTextProps) => {
  const { elementProps } = dynamicTextProps
  const { element, initialHtml } = elementProps

  const { getCaseDetail } = dynamicTextUtilities(dynamicTextProps)
  const { path, value } = getCaseDetail(element)

  const divTemplate = `<div class="mceNonEditable" data-case-detail="${path}">${value}</div>`
  const templateElement = load(divTemplate)
  const initialHtmlElementLoaded = load(initialHtml)
  const initialHtmlElement = initialHtmlElementLoaded('body')

  preserveStyling({
    initialElement: element,
    templateElement: templateElement,
  })

  preserveMceSpanFontStyle({
    initialElement: initialHtmlElement,
    targetElement: templateElement,
    targetTag: 'p',
  })

  preserveItalicsAndBolds({
    initialElement: element,
    targetElement: templateElement,
    targetTag: 'p',
    findLast: false,
  })

  element.replaceWith(templateElement.html())
}
