import styled, { css } from 'styled-components'
import editorItem from 'styles/editorItem'

export const Text = styled.div((props) => {
  const { editing, edits } = props

  return css`
    ${editorItem}
    cursor: ${editing && 'text'};
    height: auto;
    border-radius: ${edits?.borderRadius || 0}px;
    box-shadow: ${edits?.dropShadow
      ? `${edits?.dropShadow?.xOffset}px ${edits?.dropShadow?.yOffset}px
      ${edits?.dropShadow?.blur}px ${edits?.dropShadow?.spread}px
      ${edits?.dropShadow?.color}`
      : `${edits?.boxShadow}`};
    border: ${edits?.borderThickness}px ${edits?.borderThickness && 'solid'}
      ${edits?.borderColor};
    border-radius: ${edits?.borderRadius};
    opacity: ${edits?.opacity};
    transform: ${edits?.transform};
    ${edits?.textShadow
      ? `text-shadow: ${edits?.textShadow?.xOffset}px ${edits?.textShadow?.yOffset}px ${edits?.textShadow?.blur}px ${edits?.textShadow?.color};`
      : ''}
  `
})

export const TextContainer = styled.div((props) => {
  const {
    editing,
    cantHighlight,
    itemState,
    resizingState,
    contentSizeAtStartOfResize,
  } = props

  const isLoading = itemState === 'loading'
  const isDebugging = itemState === 'debugging'
  const isResizing = resizingState === 'resizing'

  const contentSize = () => {
    if (!isResizing) {
      const defaultSizes = { width: '100%', height: 'auto' }
      return defaultSizes
    }

    // lock the width and height of the content when resizing
    const heightWhenStartDragging = contentSizeAtStartOfResize?.height || 0
    const height = `${heightWhenStartDragging}px`

    const widthWhenStartDragging = contentSizeAtStartOfResize?.width || 0
    const width = `${widthWhenStartDragging}px`

    const contentSizeWhenStartResizing = { width, height }
    return contentSizeWhenStartResizing
  }

  const { width, height } = contentSize()

  const backgroundColor = () => {
    if (isDebugging) return 'rgba(255,255,255,0.25)'
    return 'transparent'
  }

  const opacity = () => {
    if (isResizing) return 0.5
    if (isLoading) return 0
    return 1
  }

  return css`
    user-select: ${cantHighlight ? 'none' : 'auto'};
    pointer-events: ${!editing && 'none'};
    opacity: ${opacity()};
    width: ${width};
    height: ${height};
    background-color: ${backgroundColor()};
    transition: opacity 0.1s ease-in-out;
  `
})

export const LoadingState = styled.div((props) => {
  const { x, y, width, height, itemState, environment } = props
  const isLoading = itemState === 'loading'

  const previewAndPDFPosition = css`
    top: ${y}px;
    left: ${x}px;
    width: ${width}px;
    height: ${height}px;
  `

  const editorPosition = css`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  `

  const isEditor = environment === 'editor'
  const positioning = isEditor ? editorPosition : previewAndPDFPosition

  return css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 100;
    pointer-events: none;
    backdrop-filter: blur(32px);
    border-radius: 5px;
    drop-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    opacity: ${isLoading ? 1 : 0};
    transition: opacity 0.25s ease-in-out;
    ${positioning}

    @keyframes pulsate {
      0% {
        transform: scale(0.1);
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        transform: scale(1.2);
        opacity: 0;
      }
    }

    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #333;
      animation: pulsate 1s infinite;
    }
  `
})

export const OverflowMessage = styled.div((props) => {
  const { x, y, itemState, environment } = props

  const isEditor = environment === 'editor'
  const isOverflowing = itemState === 'content-overflowing'

  const opacity = () => {
    if (isOverflowing) return 1
    return 0
  }

  const previewAndPDFPosition = css`
    top: ${y - 25}px;
    left: ${x - 2}px;
  `

  const editorPosition = css`
    top: -25px;
    left: -2px;
  `

  const positioning = isEditor ? editorPosition : previewAndPDFPosition

  return css`
    position: absolute;
    white-space: nowrap;
    font-size: 10px;
    font-weight: bold;
    background-color: rgba(255, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    drop-shadow: 0 5px 15px rgba(0, 0, 0, 0.75);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 100;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
    opacity: ${opacity()};
    ${positioning}
  `
})
