import { useState, useEffect } from 'react'
import {
  funeralHomeAtom,
  objectKeySelector,
  showPinturaAtom,
  caseAtom,
  itemSelector,
  fhLogoAtom,
  rightMenuAtom,
} from 'atoms'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import * as S from './PageImage.styled'
import getImageDimensions from 'utils/editor/getImageDimensions'

export default ({
  itemRef,
  image: { url, editedUrl } = {},
  properties: { subtype, caseImage } = {},
  images,
  id,
  maintainAspectRatio,
  width,
  height,
  type,
  assetLocked,
  ...props
}) => {
  const setShowPintura = useSetRecoilState(showPinturaAtom)
  const activeMenu = useRecoilValue(rightMenuAtom)
  const isElementsMenu = activeMenu === 'Elements'
  const showPintura = () => setShowPintura(true)
  const [imgSrc, setImgSrc] = useState(null)
  const fhLogo = useRecoilValue(fhLogoAtom)
  const logo = useRecoilValue(
    objectKeySelector({ atom: funeralHomeAtom, property: 'logo' }),
  )
  const _case = useRecoilValue(caseAtom)
  const { personal_images, filledImages } = _case || {}
  const [itemWidth, setItemWidth] = useRecoilState(
    itemSelector({ atomFamily: type, id: id, path: 'width' }),
  )
  const [itemHeight, setItemHeight] = useRecoilState(
    itemSelector({ atomFamily: type, id: id, path: 'height' }),
  )

  const setImage = () => {
    let final
    const indexURL = `${filledImages?.[caseImage]}`
    const logoSrc = `${process.env.REACT_APP_S3BUCKET_FH_LOGOS}${
      logo || fhLogo
    }`
    const extractedUrl = url?.includes('url(')
      ? url.match(/\((.*?)\)/)?.[1]?.replace(/('|")/g, '')
      : null

    if (url?.includes('placeholder') && filledImages?.[caseImage]) {
      final = indexURL
    } else if (subtype === 'logoImage' && (logo || fhLogo)) {
      final = logoSrc
    } else if (url?.includes('url(') && extractedUrl) {
      final = extractedUrl
    } else if (editedUrl) {
      final = editedUrl
    } else {
      final = url
    }

    setImgSrc(final)
  }

  const getDimensions = async (width, src) => {
    try {
      setItemWidth(width)
      if (maintainAspectRatio) {
        setItemHeight(height)
      } else {
        const d = await getImageDimensions(src)
        setItemHeight(width * (d.height / d.width))
      }
    } catch (err) {
      console.log(src, err)
    }
  }

  useEffect(() => {
    setImage()
    //eslint-disable-next-line
  }, [
    personal_images,
    caseImage,
    subtype,
    url,
    editedUrl,
    logo,
    fhLogo,
    filledImages,
  ])

  useEffect(() => {
    imgSrc && getDimensions(width, imgSrc)
    //eslint-disable-next-line
  }, [imgSrc])

  const handleDoubleClick = () => {
    const lockedAsset = assetLocked || !isElementsMenu
    const isStylizedVerse = subtype === 'stylizedVerse'

    const pinturaDisabled = lockedAsset || isStylizedVerse
    if (pinturaDisabled) return

    showPintura()
  }

  return (
    <S.Image
      onDoubleClick={handleDoubleClick}
      ref={itemRef}
      imgSrc={imgSrc}
      width={itemWidth}
      height={itemHeight}
      type={type}
      {...props}
    />
  )
}
