import { useTinyMCESettings } from '.'
import { Editor } from '@tinymce/tinymce-react'

export default (props) => {
  const {
    value = '',
    init: additionalInit,
    inline,
    setRef,
    caseDetails,
    isVerse = false,
    setToast,
    setTinyMCERefTrigger,
    updateHeight = () => null,
    ...rest
  } = props

  const tinyMCEProps = {
    additionalInit,
    inline,
    caseDetails,
    isVerse,
    setToast,
    updateHeight,
  }

  const init = useTinyMCESettings(tinyMCEProps)

  const onInit = (evt, editor) => {
    setRef?.(editor)
    if (editor) setTinyMCERefTrigger?.(Date.now())
  }

  return (
    <Editor
      onInit={onInit}
      tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js"
      initialValue={value}
      init={init}
      {...rest}
    />
  )
}
