import { useState } from 'react'
import { Dropdown, Button, Link, FullscreenModal } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { FinalizeModal, PrintGuideModal } from './'
import { generatePDF as generatePDFApi, generatePDFStatus } from 'api/orders'
import { useRecoilValue } from 'recoil'
import { productsAtom, roleAtom } from 'atoms'
import { useLocation } from 'react-router-dom'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { GeneratingModal } from 'components/package/GeneratingModal'
import DesignerGate from 'components/auth/DesignerGate'
import { useSetToast } from 'hooks'
import buildUrlWithParams from 'utils/global/buildUrlWithParams'

export default ({
  order,
  productInfo,
  productLineInfo,
  themeInfo,
  selectedLayout: [selectedLayout, setSelectedLayout] = ['', null],
  selectedProducts: [selectedProducts, setSelectedProducts] = ['', null],
  layout: [layout, setLayout] = ['', null],
  productStatus: [productStatus, setProductStatus] = ['', productInfo.status],
  pageSet,
  allLayouts,
  oldProduct,
  isLoading,
  getOrderProducts,
}) => {
  const handleError = useErrorHandler('CustomizeProductHeader')
  const [showPrintGuide, setShowPrintGuide] = useState(false)
  const [generating, setGenerating] = useState('')
  const [showGeneratingModal, setShowGeneratingModal] = useState(false)
  const [showFinalizeModal, setShowFinalizeModal] = useState(false)
  const [generationState, setGenerationState] = useState({})
  const products = useRecoilValue(productsAtom)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const setToast = useSetToast()
  const role = useRecoilValue(roleAtom)

  const isFHDirector = role === 'fh-user'
  const isMessengerUser = role === 'messenger-user'

  const productStatusValues = [
    { id: 'EDITING', name: 'Editing' },
    { id: 'PROOF_SENT', name: 'Proof Sent' },
    { id: 'FINALIZED', name: 'Finalized' },
    { id: 'CLOSED', name: 'Closed' },
  ]

  const generatePDF = async (isProof) => {
    setGenerating(isProof ? 'proof' : 'final')
    setShowGeneratingModal(true)
    let body = {
      html: isProof ? 'pdfProofProduct' : 'pdfProduct',
      page_height: selectedLayout.paper_height,
      page_width: selectedLayout.paper_width,
      order_id: order.id,
      order_page_set_id: pageSet.id,
      product_id: productInfo.id,
    }

    try {
      let isPdfComplete = false
      // only fetch request used in this app (instead of axios), also noted in api files
      const response = await generatePDFApi(body)
      setGenerationState((prevState) => ({ ...prevState, status: 'IN_QUEUE' }))
      if (response) {
        const checkPDFStatus = async (id) => {
          let PDFstatus = await generatePDFStatus(id)
          while (
            PDFstatus.progress !== 100 &&
            PDFstatus.progress !== undefined
          ) {
            await new Promise((resolve) => setTimeout(resolve, 2000)) // Wait for 2 seconds
            // TODO: Fix potential state synchronization issue
            // eslint-disable-next-line no-loop-func
            setGenerationState((prevState) => ({
              ...prevState,
              progress: PDFstatus.progress,
              status: 'GENERATING',
            }))
            PDFstatus = await generatePDFStatus(id)
          }
          setGenerationState(PDFstatus)
          return PDFstatus
        }

        const PDFstatus = await checkPDFStatus(response.id)

        isPdfComplete =
          PDFstatus.progress === 100 &&
          PDFstatus.status === 'COMPLETE' &&
          PDFstatus.pdf

        if (isPdfComplete) {
          setGenerating('')

          const completeText = isProof
            ? 'Successfully sent proof'
            : 'Successfully finalized'

          isFHDirector &&
            setToast({
              icon: 'check',
              text: completeText,
            })
        }
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message
      const msg = errorMessage?.toLowerCase()
      const isTimeout = msg?.includes('Timeout error navigating to URL')
      setGenerationState({ status: isTimeout ? 'TIMEOUT' : 'ERROR' })
      handleError(errorMessage, error)
    }
  }

  const isPMRegisterBook = (name) => {
    return (
      productLineInfo?.id === '00000000-57c7-4c01-caa5-ce2103a01c53' &&
      name.includes('Register Book') &&
      !name.includes('Front Insert') &&
      !name.includes('Extra Pages')
    )
  }

  const needsFrontInsert = (name) => {
    return selectedProducts.some(
      (product) =>
        product.name === `${name}: Front Insert` && !product.selected,
    )
  }

  const addExtraPages = async () => {
    try {
      const extraPages = products.find(
        (product) =>
          product.name.includes(`${productInfo.name}: Extra Pages`) &&
          product.collection_id === productInfo.collection_id,
      )
      setSelectedProducts(extraPages)
      /*TODO: the poduct gets sent to the DB in the selector above, 
      but we don't get the data back because selectors can't be async - 
      so we need to refetch the products, if we do that _immediately_ 
      then the selector hasn't finished and we don't get the new addition, 
      so a short wait resolved that, this could be made more elegant, 
      but this timeout works for now :(*/
      setTimeout(() => getOrderProducts(), 500)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const addFrontInsert = () => {
    try {
      const frontInsert = products.find(
        (product) =>
          product.name.includes(`${productInfo.name}: Front Insert`) &&
          product.collection_id === productInfo.collection_id,
      )
      setSelectedProducts(frontInsert)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const goToV1 = () => {
    const V1 = `https://directorsprintsuite.com/order/${order.id}/${order.case_id}`
    window.open(V1, '_blank')
  }

  const isFinalized = productStatus === 'FINALIZED'
  const isClosed = productStatus === 'CLOSED'

  const finalizeButtonText = () => {
    if (isFinalized) return 'Finalized'
    if (generating === 'final') return 'Finalizing...'
    return 'Finalize'
  }

  const proofButtonText = () => {
    if (generating === 'proof') return 'Generating Proof...'
    return 'Generate Proof in Dashboard'
  }

  const finalize = () => {
    const hideFinalizeWarning = localStorage.getItem('hideFinalizeWarning')
    if (!hideFinalizeWarning) {
      setShowFinalizeModal(true)
      return
    }

    generatePDF(false)
    return
  }

  const generatingModalProps = {
    setShowGeneratingModal,
    generationState,
    setGenerationState,
    getOrderProducts,
    isMessengerUser,
    productStatus,
  }

  const finalizeModalProps = {
    setShowFinalizeModal,
    generatePDF,
  }

  return (
    <>
      <Flex column>
        <Flex
          margin={isPMRegisterBook(productInfo.name) ? '0 0 0 0' : '0 0 32px'}
          padding="0 40px"
          justify="space-between"
          align="center"
        >
          <Flex column>
            <Text size="24px" weight="600">
              {productInfo?.name}
            </Text>
            <Text>
              {productLineInfo?.name} • {themeInfo?.name}
            </Text>
          </Flex>
          <Flex>
            <DesignerGate>
              <Dropdown
                selected={[layout, setLayout]}
                placeholder="Layout:"
                textKey="name"
                valueKey="id"
                label="Layout"
                items={allLayouts}
                margin="0 16px 0 0"
              />
            </DesignerGate>
            <DesignerGate>
              <Dropdown
                selected={[productStatus, setProductStatus]}
                placeholder="Status"
                textKey="name"
                valueKey="id"
                label="Product Status"
                items={productStatusValues}
                margin="0 16px 0 0"
              />
            </DesignerGate>

            {!oldProduct && (
              <Flex gap="16px">
                {!isFinalized && (
                  <>
                    <Button
                      margin="19px 0 0 0"
                      onClick={() => generatePDF(true)}
                      disabled={isLoading || generating || oldProduct}
                    >
                      {proofButtonText()}
                    </Button>
                    <Flex disabled={isLoading || oldProduct}>
                      <Link
                        disabled={generating}
                        href={buildUrlWithParams(
                          `/editor/${pageSet?.id}`,
                          params,
                        )}
                      >
                        <Button margin="19px 0 0 0" disabled={generating}>
                          Customize
                        </Button>
                      </Link>
                    </Flex>
                  </>
                )}
                {isFinalized && (
                  <Button
                    margin="19px 0 0 0"
                    onClick={() => setProductStatus('EDITING')}
                  >
                    Revert Finalization
                  </Button>
                )}
                {!isClosed && (
                  <Button
                    margin="19px 0 0 0"
                    onClick={finalize}
                    disabled={
                      isLoading || generating || oldProduct || isFinalized
                    }
                    primary
                  >
                    {finalizeButtonText()}
                  </Button>
                )}
              </Flex>
            )}
            {oldProduct && (
              <Button primary margin="19px 16px 0 0" onClick={() => goToV1()}>
                Open on V1
              </Button>
            )}
          </Flex>
        </Flex>

        {isPMRegisterBook(productInfo.name) && (
          <Flex justify="flex-end" margin="0 0 32px" padding="0 40px">
            <Button margin="19px 16px 0 0" onClick={addExtraPages}>
              Add Extra Pages
            </Button>
            {needsFrontInsert(productInfo.name) && (
              <Button margin="19px 0px 0 0" onClick={addFrontInsert}>
                Add Front Insert
              </Button>
            )}
          </Flex>
        )}
        <PrintGuideModal
          show={[showPrintGuide, setShowPrintGuide]}
          selectedLayout={selectedLayout}
          productInfo={productInfo}
          productLineInfo={productLineInfo}
        />
      </Flex>
      <FullscreenModal
        show={[showGeneratingModal, setShowGeneratingModal]}
        hideClose
      >
        <GeneratingModal {...generatingModalProps} />
      </FullscreenModal>
      <FullscreenModal show={[showFinalizeModal, setShowFinalizeModal]}>
        <FinalizeModal {...finalizeModalProps} />
      </FullscreenModal>
    </>
  )
}
