import styled, { css } from 'styled-components'
import { ResizableBox } from 'react-resizable'
import Icon from 'components/priority/Icon/Icon'
import colors from 'utils/styling/colors'
import zLayers from 'utils/styling/zLayers'

const TopPosition = css`
  top: 5px;
`

const BottomPosition = css`
  bottom: 5px;
`

const LeftPosition = css`
  left: 5px;
`

const RightPosition = css`
  right: 5px;
`

const CommonIconStyles = css`
  position: absolute;
  z-index: ${({ z }) => zLayers.addOne(z)};
  visibility: ${({ selected, itemLocked }) =>
    selected && !itemLocked ? 'visible' : 'hidden'};
`

export const DeleteItem = styled(Icon)(
  () => css`
    ${CommonIconStyles}
    ${TopPosition}
    ${RightPosition}
    cursor: pointer;
  `,
)

export const SwapImage = styled(Icon)(
  () => css`
    ${CommonIconStyles}
    ${TopPosition}
    ${LeftPosition}
    cursor: pointer;
    background-color: #fff;
  `,
)

export const LockItem = styled(Icon)(
  () => css`
    ${CommonIconStyles}
    ${BottomPosition}
    ${LeftPosition}
    background-color: #fff;
    border-radius: 5px;
  `,
)

export const ResizeControls = styled(ResizableBox)(
  ({ selected, rotate, disabled }) => css`
    width: 100%;
    height: 100%;
    position: relative;
    outline: ${selected && `2px solid`};
    outline-color: ${selected && colors('brand', 75)};
    ${disabled && `z-index: ${zLayers.behindBackground};`}
    ${rotate ? `transform: rotate(${rotate}deg);` : ''}
  `,
)

export const ResizeHandle = styled.div(
  ({ z, position, selected }) => css`
    ${CommonIconStyles}
    ${BottomPosition}
    ${RightPosition}
    width: 15px;
    height: 15px;
    border-bottom: solid 7px ${colors('brand', 75)};
    border-right: solid 7px ${colors('brand', 75)};
    color: #fff;
    border-left: solid 7px transparent;
    border-top: solid 7px transparent;
    cursor: ${`${position}-resize`};
    z-index: ${zLayers.addOne(z)};
  `,
)
