import type { CheerioAPI } from 'cheerio'
import type { CheerioElement } from 'types/global'
import preserveStylingUtilities from './preserveStylingUtilities'

const extractFontSize = (style: string): string | null => {
  if (!style) return null
  const match = style.match(/font-size\s*:\s*([^;]+);?/i)
  return match ? `font-size: ${match[1]};` : null
}

const preserveMceSpanFontStyle = ({
  initialElement,
  targetElement,
  targetTag,
}: {
  initialElement: CheerioElement
  targetElement: CheerioAPI
  targetTag: string
}) => {
  if (!initialElement || !targetElement || !targetTag) return

  const { extractCurrentStyle } = preserveStylingUtilities()

  const extractedStyle = extractCurrentStyle(initialElement)
  if (!extractedStyle) return

  let fontSizeStyle = extractFontSize(extractedStyle)

  const caseDetailDiv = targetElement('div[data-case-detail]')
  const isCaseDetailDivNotFound = !caseDetailDiv || caseDetailDiv.length === 0
  if (isCaseDetailDivNotFound) return

  const caseDivInlineStyle = caseDetailDiv.attr('style')
  if (!caseDivInlineStyle) return

  if (!fontSizeStyle) {
    /* We define a default font size for the obituary when font size is not found from its spans */
    fontSizeStyle = 'font-size: 11pt;'
  }

  /* Remove the font size style in the div container to avoid conflicts on spans font sizes  */
  const cleanStyle = (attr: string) => {
    const value = caseDetailDiv.attr(attr)
    if (value) {
      const newValue = value.replace(/font-size\s*:\s*[^;]+;?/gi, '').trim()
      if (newValue) {
        caseDetailDiv.attr(attr, newValue)
      } else {
        caseDetailDiv.removeAttr(attr)
      }
    }
  }

  cleanStyle('style')
  cleanStyle('data-style')
  cleanStyle('data-mce-style')

  /* Iterate over paragraphs to insert a span with the font size prop and mce attribute */
  targetElement(targetTag).each((_, element) => {
    const loadedElement = targetElement(element)
    let lastElement = null

    const lastSpan = loadedElement.find('span').last()
    const lastParagraph = loadedElement.find('p').last()
    lastElement = lastSpan.length > 0 ? lastSpan : lastParagraph

    let content =
      lastElement.length > 0 ? lastElement.text() : loadedElement.text()

    const styleAttr = fontSizeStyle ? ` style="${fontSizeStyle}"` : ''
    const mceStyleAttr = fontSizeStyle
      ? ` data-mce-style="${fontSizeStyle}"`
      : ''

    const newContent = `<span${styleAttr}${
      mceStyleAttr ? ` ${mceStyleAttr}` : ''
    }>${content}</span>`

    loadedElement.html(newContent)
  })
}

export default preserveMceSpanFontStyle
