import React, { useState, useRef, useCallback } from 'react'
import { Button, Input, TinyMCE, FileDropzone } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Tooltip from 'components/global/Tooltip/Tooltip'
import { PersonalImageCard } from 'components/dashboard/CaseDetails'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'
import {
  createCustomVerse,
  uploadStylizedVerseImage,
  removeStylizedVerseImage,
  getCustomVerses,
} from 'api/customVerses'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  roleAtom,
  stylizedVersesAtom,
  themeLayoutAtom,
  editorProductIdAtom,
} from 'atoms'
import { useSetToast } from 'hooks'

const CustomVerseEditor = ({ setCreatingVerse, stylizedVerse }) => {
  const role = useRecoilValue(roleAtom)
  const setStylizedVerses = useSetRecoilState(stylizedVersesAtom)
  const themeLayout = useRecoilValue(themeLayoutAtom)
  const product = useRecoilValue(editorProductIdAtom)
  const handleError = useErrorHandler('CustomVerseEditorMenu')
  const TinyMCERef = useRef(null)
  const setToast = useSetToast()
  const isMessengerUser = role === 'messenger-user'

  const [reference, setReference] = useState('')
  const [verseImage, setVerseImage] = useState(null)
  const [isGlobalVerse, setIsGlobalVerse] = useState(false)
  const [isThemeVerse, setIsThemeVerse] = useState(false)
  const [imagesLoading, setImagesLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)

  const { product_id: defaultProductId, theme_id: defaultThemeId } =
    themeLayout || {}

  const uploadVerseImage = async (file) => {
    setImagesLoading(true)
    try {
      let fd = new FormData()
      fd.append('file', file[0], 'blob')
      const resp = await uploadStylizedVerseImage(fd)
      setImagesLoading(false)
      if (resp.data) setVerseImage(resp.data)
      return resp.data
    } catch (error) {
      handleError(
        error?.response?.data?.message || error.message,
        error,
        null,
        'uploadStylizedVerseImage',
      )
      throw error
    }
  }

  const removeVerseImage = async () => {
    try {
      const resp = await removeStylizedVerseImage(stylizedVerse?.id, {
        filename: verseImage,
      })
      if (resp) setVerseImage(null)
    } catch (error) {
      handleError(
        error?.response?.data?.message || error.message,
        error,
        null,
        'removeVerseImage',
      )
    }
  }

  const createVerse = async () => {
    if (isCreating) return
    setIsCreating(true)
    const canNotCreate = isMessengerUser && !isGlobalVerse && !isThemeVerse
    if (canNotCreate) {
      setToast({
        text: 'Stylized verse needs to be global or theme/product based. Cannot be empty.',
      })
      setIsCreating(false)
      return
    }
    try {
      let base_product_id = null
      if (isThemeVerse && !isGlobalVerse) {
        base_product_id = defaultProductId
      }
      const content = TinyMCERef?.current?.getContent?.()
      const newVerse = {
        html: content || '',
        html_for_render: stylizedVerse ? '' : content,
        reference: reference,
        isGlobal: isGlobalVerse,
        type: stylizedVerse ? 'IMAGE' : 'HTML',
        image_url: verseImage,
        base_product_id,
        theme_id: isGlobalVerse ? null : isThemeVerse ? defaultThemeId : null,
        order_product_id: !isMessengerUser && stylizedVerse ? product : null,
      }
      await createCustomVerse(newVerse)
      const stylizedVersesData = await getCustomVerses(
        defaultThemeId,
        defaultProductId,
        product,
      )
      setStylizedVerses(stylizedVersesData.data)
      setCreatingVerse(false)
      setIsCreating(false)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const setEditorRef = useCallback((ref) => {
    if (!TinyMCERef.current) TinyMCERef.current = ref
  }, [])

  const MemoizedTinyMCE = React.memo(TinyMCE)

  const canUploadVerseImage = stylizedVerse && !verseImage

  return (
    <Flex column full>
      {!stylizedVerse && (
        <Flex
          border="1px solid"
          borderColor="gray4"
          radius="2px"
          minHeight="200px"
          margin="0 0 12px 0"
          full
        >
          <MemoizedTinyMCE
            placeholder="Type text here"
            value={undefined}
            setRef={setEditorRef}
            isVerse
          />
        </Flex>
      )}

      {canUploadVerseImage && (
        <Flex full column>
          <FileDropzone
            height="200px"
            margin="0 0 24px 0"
            text="Drag and drop file here to create a new verse"
            fileTypes={['.png', '.jpeg', '.jpg', '.gif']}
            allowMultiple={false}
            onDrop={(file) => uploadVerseImage(file)}
            isLoading={imagesLoading}
          />
        </Flex>
      )}

      {verseImage && (
        <Flex full column width="100%">
          <PersonalImageCard
            image={verseImage}
            index={0}
            makeDefault={false}
            deleteImage={() => removeVerseImage()}
            margin="0 0 24px 0"
            showDefault={true}
          />
        </Flex>
      )}
      <Input
        margin="0 0 12px 0"
        label="Reference"
        value={[reference, setReference]}
      />

      {isMessengerUser && (
        <Flex
          justify="flex-start"
          align="flex-start"
          margin="16px 0"
          gap="8px 0"
          column
        >
          <Tooltip text="Make this verse global" margin="0 12px 0 0">
            <ToggleSwitch
              label="Is global verse"
              labelRight
              value={[isGlobalVerse, setIsGlobalVerse]}
              disabled={!isMessengerUser}
            />
          </Tooltip>
          {stylizedVerse && !isGlobalVerse && (
            <Tooltip
              text="Link verse to product/theme combo"
              margin="0 12px 0 0"
            >
              <ToggleSwitch
                label="Is product/theme verse"
                labelRight
                value={[isThemeVerse, setIsThemeVerse]}
                disabled={!isMessengerUser}
              />
            </Tooltip>
          )}
        </Flex>
      )}

      <Flex>
        <Button onClick={() => setCreatingVerse(false)} margin="0 12px 0 0">
          Cancel
        </Button>
        <Button primary onClick={createVerse}>
          Save
        </Button>
      </Flex>
    </Flex>
  )
}

const Wrapper = ({ setCreatingVerse, stylizedVerse }) => (
  <Flex full padding="24px">
    <CustomVerseEditor
      setCreatingVerse={setCreatingVerse}
      stylizedVerse={stylizedVerse}
    />
  </Flex>
)

export default Wrapper
