import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { toastsAtom } from 'atoms'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import isArray from 'lodash/isArray'
import type { Toast } from 'atoms'

const useSetToast = () => {
  const [toasts, setToasts] = useRecoilState(toastsAtom)
  const toastsLoadable = useRecoilValueLoadable(toastsAtom)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (toastsLoadable.state === 'hasValue') setIsLoaded(true)
  }, [toastsLoadable.state])

  const addToastsFunction = (addToasts: Toast | Toast[]) => {
    if (!isLoaded) return

    if (isArray(addToasts)) {
      const newToasts = addToasts.map((toast) => ({
        ...toast,
        uuid: uuidv4(),
      }))
      setToasts([...toasts, ...newToasts])
    } else {
      const newToast = { ...addToasts, uuid: uuidv4() }
      setToasts([...toasts, { ...newToast }])
    }
  }

  return addToastsFunction
}

export default useSetToast
