import { useState, useEffect } from 'react'
import {
  EditorHeader,
  InsertMenu,
  Canvas,
  PropertiesMenu,
  FullscreenModal,
  Pintura,
  SlideoutMenu,
  Loading,
} from 'components'
import Flex from 'components/priority/Flex/Flex'
import {
  updatePageItems,
  useSetToast,
  useNavigate,
  useResetEditorAtoms,
  useDynamicStyles,
  useStonlyWidget,
} from 'hooks'
import { getThemeLayout, getWatermark } from 'api/themes'
import { getEditorOrderPageDetails, updateOrderPage } from 'api/orderPages'
import { getFuneralHomeSettings } from 'api/funeralHomeSettings'
import { getDesignAssets } from 'api/designAssets'
import { getCustomVerses } from 'api/customVerses'
import {
  caseSelector,
  editorPagesAtom,
  itemIdAtom,
  showPinturaAtom,
  unsavedChangesAtom,
  dateFormatAtom,
  editorLayoutAtom,
  editorProductIdAtom,
  editorOrderPageSetAtom,
  triggerUseEffect,
  editorClipboardAtom,
  showUnsavedModalAtom,
  backgroundImageAtom,
  themeLayoutAtom,
  designAssetsAtom,
  themeIdAtom,
  roleAtom,
  itemsLockedAtom,
  stylizedVersesAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { UnsavedModal } from 'components'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { set } from 'lodash'
import editorGlobal from 'styles/editorGlobal'
import buildUrlWithParams from 'utils/global/buildUrlWithParams'
import funeralHomeDateFormat from 'utils/dates/funeralHomeDateFormat'
import zLayers from 'utils/styling/zLayers'
import lockedPallete from 'utils/styling/lockedPalette'

export default () => {
  const handleError = useErrorHandler('Editor')
  const [editorData, setEditorData] = useState({})
  const [themeData, setThemeData] = useState({})
  const [pages, setPages] = useRecoilState(editorPagesAtom)
  const [header, setHeader] = useState('')
  const location = useLocation()
  const setCase = useSetRecoilState(caseSelector)
  const setEditorLayout = useSetRecoilState(editorLayoutAtom)
  const setEditorProductId = useSetRecoilState(editorProductIdAtom)
  const setEditorOrderPageSet = useSetRecoilState(editorOrderPageSetAtom)
  const itemIds = useRecoilValue(itemIdAtom)
  const setDateFormat = useSetRecoilState(dateFormatAtom)
  const [initialLoad, setInitialLoad] = useState(true)
  const updateItems = updatePageItems()
  const setToast = useSetToast()
  const [showPintura, setShowPintura] = useRecoilState(showPinturaAtom)
  const [itemsLocked, setItemsLocked] = useRecoilState(itemsLockedAtom)
  const [showUnsavedModal, setShowUnsavedModal] =
    useRecoilState(showUnsavedModalAtom)
  const navigate = useNavigate()
  const [goBack, setGoBack] = useState(false)
  const resetEditorValues = useResetEditorAtoms()
  const [showSlideout, setShowSlideout] = useState(false)
  const [unsavedChanges, setUnsavedChanges] = useRecoilState(unsavedChangesAtom)
  const [saveClick, setSaveClick] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const trigger = useRecoilValue(triggerUseEffect)
  const setClipboard = useSetRecoilState(editorClipboardAtom)
  const setBackgroundImage = useSetRecoilState(backgroundImageAtom)
  const setThemeLayout = useSetRecoilState(themeLayoutAtom)
  const setThemeId = useSetRecoilState(themeIdAtom)
  const setDesignAssets = useSetRecoilState(designAssetsAtom)
  const setStylizedVerses = useSetRecoilState(stylizedVersesAtom)
  const userRole = useRecoilValue(roleAtom)
  const isRegularUser = userRole !== 'messenger-user'
  const { pathname, search } = location
  const params = new URLSearchParams(search)

  useDynamicStyles(editorGlobal)
  useStonlyWidget()

  const fetchWatermarks = async (watermarkId, watermarksArray) => {
    const { data: watermark } = await getWatermark(watermarkId)
    //set the required data fields and do the math to translate them to 96dpi
    //TODO: once the old site is decommissioned we can run a script to do this globally
    watermark.type = 'watermark'
    watermark.y = (watermark.top / 72) * 96
    watermark.x = (watermark.left / 72) * 96
    watermark.height = (watermark.height / 72) * 96
    watermark.width = (watermark.width / 72) * 96
    watermark.z = 1
    //add the watermark to the array of watermarks
    watermarksArray.push(watermark)
  }

  const getData = async () => {
    const [, , orderPageSetId] = pathname.split('/')
    try {
      const { data } = await getEditorOrderPageDetails(orderPageSetId)
      setThemeId(data.theme.id)
      const designAssetsData = await getDesignAssets(data.theme.id)
      setDesignAssets(designAssetsData.data.designAssets)
      const stylizedVersesData = await getCustomVerses(
        data?.theme_layout[0].theme_id,
        data?.theme_layout[0].product_id,
        data?.product?.id,
      )
      setStylizedVerses(stylizedVersesData.data)
      setEditorLayout(data?.layout?.layout_id)
      setEditorProductId(data?.product?.id)
      const { data: settings } = await getFuneralHomeSettings()
      const fetchedWatermarks = []

      //fetch the watermarks if they exist
      if (data.theme_layout.length > 0) {
        const { theme_layout } = data

        if (theme_layout[0].first_watermark)
          await fetchWatermarks(
            theme_layout[0].first_watermark,
            fetchedWatermarks,
          )

        if (theme_layout[0].second_watermark)
          await fetchWatermarks(
            theme_layout[0].second_watermark,
            fetchedWatermarks,
          )
      }

      const format = funeralHomeDateFormat(settings.date_format)
      setDateFormat(format)

      const pageData = []

      data.pages.map((page, index) => {
        const { json_template } = page

        if (!json_template?.product?.themeLayout?.themeId) {
          set(json_template, 'product.themeLayout.themeId', data.theme.id)
        }
        const items = json_template?.product?.items?.map((item) => {
          //the backend is parsing this in so lets
          //TODO remove this uuid addition
          const uuid = uuidv4()
          item.id = uuid
          return item
        })
        json_template.product.items = items
        //set a variable to check for watermark matches
        let matchedMarks
        //if the json template has watermarks and is page 1 (I think interior pages do not have watermarks 🤔)
        if (
          json_template?.product?.watermarks?.length > 0 &&
          json_template.pageProperties.pageNumber === 1
        ) {
          //check to see if the currently set watermarks match the incoming watermarks
          //we want to see if these are the same watermarks as on the currently selected theme
          //we'll want to set some values in the future to track if they replaced the watermarks (then the ids won't match but we won't want to update them)
          //TODO - create logic for handling replaced watermarks and replaced 1 watermark
          matchedMarks = fetchedWatermarks.find((mark) => {
            return json_template.product.watermarks.find(
              (storedMark) => storedMark.id === mark.id,
            )
          })
        }
        //if there are no matches (the watermarks are new to this package) and it's page 1, set the watermarks to be the ones fetched from the db
        if (!matchedMarks && json_template.pageProperties.pageNumber === 1) {
          json_template.product.watermarks = fetchedWatermarks
        }
        //we need the order page id more easily accessible for save, maybe backend can do this but no time for that now in budget
        json_template.product.orderPageId = page.id

        pageData.push(page.json_template)
        return page
      })

      setCase(data.case)
      setHeader(
        `${data?.case?.name_of_deceased} | ${data?.product?.name}: ${data?.layout?.name} | ${data?.theme?.name}`,
      )

      const sortedPages = pageData.sort((a, b) =>
        a.pageProperties.pageNumber < b.pageProperties.pageNumber ? -1 : 0,
      )

      const themeLayout = await getThemeLayout(
        data?.theme.id,
        data?.layout?.layout_id,
      )
      // product font color OVERRIDES theme font color
      const productFontColor = data?.product?.font_color
      const themeFontColor = data?.theme?.font_color
      const defaultFontColor = lockedPallete.lockedBlack.default
      const fontColor = productFontColor || themeFontColor || defaultFontColor
      setItemsLocked(isRegularUser)
      setEditorData(data)
      setThemeData({
        ...data.theme,
        font_color: fontColor,
      })
      setBackgroundImage(data.theme?.image)
      setPages(sortedPages)
      setInitialLoad(false)
      setEditorOrderPageSet(data.page_set)
      setThemeLayout(themeLayout?.data?.[0])
      setIsLoading(false)
    } catch (err) {
      handleError(err?.response?.data?.message || err.message, err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    resetEditorValues()
    getData()
    //eslint-disable-next-line
  }, [trigger])

  //save will run everytime the page json changes. right now this is only on click save button
  useEffect(() => {
    !initialLoad && saveClick && save()
    goBack &&
      navigate(
        buildUrlWithParams(`/package/${editorData.order.id}/customize`, params),
      )
    goBack && setClipboard([])
    //do we need to reset this since we navigate away?
    goBack && setGoBack(false)
    //eslint-disable-next-line
  }, [pages, goBack, saveClick])

  const save = async () => {
    const saved = []
    const error = []
    await Promise.all(
      pages.map(async (page, index) => {
        const payload = { json_template: JSON.stringify(page) }
        try {
          const { status } = await updateOrderPage(
            page.product.orderPageId,
            payload,
          )
          status === 200 && saved.push(index + 1)
        } catch (err) {
          handleError(err?.response?.data?.message || err.message, err)
          error.push(index + 1)
        }
        return page
      }),
    )

    setUnsavedChanges(false)

    saveClick &&
      saved.length === pages.length &&
      setToast({ text: 'Product saved!' })

    error.length > 0 &&
      setToast({
        text: `Something went wrong saving pages: ${error.join(', ')}`,
      })

    setSaveClick(false)
  }

  const update = async () => {
    await updateItems()
    setSaveClick(true)
  }

  const back = () => {
    !unsavedChanges && setGoBack(true)
    unsavedChanges && setShowUnsavedModal(true)
  }
  return (
    <Flex column height="100vh">
      <FullscreenModal
        hideClose={!showPintura}
        show={[showPintura, setShowPintura]}
      >
        <Pintura update={update} />
      </FullscreenModal>
      <FullscreenModal show={[showUnsavedModal, setShowUnsavedModal]}>
        <UnsavedModal
          setShow={() => setShowUnsavedModal(false)}
          orderId={editorData?.order?.id}
        />
      </FullscreenModal>
      <EditorHeader assetDetails={header} update={update} back={back} />
      <Flex
        justify="space-between"
        align={isLoading ? 'center' : 'flex-start'}
        position="relative"
        background="paleSecondary"
        height="calc(100vh - 70px)"
        borderTop="1px solid"
        borderColor="gray4"
      >
        <InsertMenu
          basePages={editorData?.pages}
          baseLayout={editorData?.layout}
          setIsLoading={setIsLoading}
          update={update}
          itemsLocked={itemsLocked}
        />

        <SlideoutMenu
          className="slide-out"
          title="Pages"
          left="250px"
          //TODO: move to styled component? there is probably a better way
          style={{ zIndex: zLayers.interface }}
          show={[showSlideout, setShowSlideout]}
          menu="PageShuffler"
          theme={themeData}
          themeLayout={editorData?.theme_layout?.[0]}
          basePages={editorData?.pages}
          baseLayout={editorData?.layout}
        />

        <Loading isLoading={isLoading}>
          {pages.length > 0 && (
            <Canvas
              id={itemIds[0]}
              theme={themeData}
              themeLayout={editorData?.theme_layout?.[0]}
              loadedPages={pages}
              setShowSlideout={setShowSlideout}
            />
          )}
        </Loading>
        <PropertiesMenu />
      </Flex>
    </Flex>
  )
}
