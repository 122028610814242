import { useCallback, useEffect, useState } from 'react'
import { produce } from 'immer'
import { default as _set } from 'lodash/set'
import { default as _omit } from 'lodash/omit'
import { createOrderPage, archiveOrderPage } from 'api/orderPages'
import { useSetToast } from 'hooks'
import {
  editorPagesAtom,
  isPageDeleting,
  selectedPageAtom,
  triggerUseEffect,
} from 'atoms'
import { useRecoilState, useRecoilCallback, useSetRecoilState } from 'recoil'
import { arrayMove, arrayRemove } from 'react-movable'
import { v4 as uuidv4 } from 'uuid'
import { getImageDimensions } from 'utils'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import isEven from 'utils/global/isEven'
import zLayers from 'utils/styling/zLayers'

export default (basePages) => {
  const handleError = useErrorHandler('usePageShuffler')
  const [pages, setPages] = useState([])
  const [disabled, setDisableAlterPages] = useState(null)
  const [loading, setLoading] = useState(true)
  const [blankPage, setBlankPage] = useState({})
  const [preventDelete, setPreventDelete] = useState(false)
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom)
  const [editorPages, setEditorPages] = useRecoilState(editorPagesAtom)
  const [isDeleting, setIsDeleting] = useRecoilState(isPageDeleting)
  const setTrigger = useSetRecoilState(triggerUseEffect)

  const setToast = useSetToast()

  const disableAlterPages = (page) => {
    const { collection, layout, product } = page?.pageProperties
    if (
      product === 'Register Book' ||
      product === 'Deluxe Register Book' ||
      product === 'Register Book: Extra Pages' ||
      product === 'Service Records' ||
      collection === 'Dignity Direct'
    ) {
      return false
    } else if (
      collection === 'Precious Memories' &&
      product === 'Church Bulletins'
    ) {
      return false
    } else if (
      collection === 'Precious Memories' &&
      product === 'Directory Signs' &&
      layout === 'Full Sheet'
    ) {
      return false
    } else {
      return true
    }
  }

  const getPages = useRecoilCallback(({ snapshot }) => async () => {
    //use the callback so we can edit the page object
    let allPages = await snapshot.getPromise(editorPagesAtom)
    //don't continue unless we actually got pages back
    if (allPages.length > 0) {
      //set the disabled sets of pages, not all pages can add/drag/remove
      const isDisabled = disableAlterPages(allPages[0])
      setDisableAlterPages(isDisabled)
      //get a page and make a copy
      const page = { ...allPages[0] }
      //from that page create a blank page for the 'add page' we will need to make further changes to this later
      const blank = produce(page, (draft) => {
        _set(draft, 'product.items', [])
      })
      //map through the pages and mark them as disabled, this drag package expects each item to be marked rather than the whole list
      allPages = allPages.map((page) => {
        let newPage = produce(page, (draft) => {
          _set(draft, 'disabled', isDisabled)
        })
        return newPage
      })
      //set the pages array, loading status, and blank page
      setPages(allPages)
      setLoading(false)
      setBlankPage(blank)
    }
  })

  useEffect(() => {
    getPages()
    // eslint-disable-next-line
  }, [editorPages])

  const createImageObj = async (src) => {
    const srcPrefix = process.env.REACT_APP_S3BUCKET_FLORAL_PIX
    const uuid = uuidv4()
    const d = await getImageDimensions(`${srcPrefix || ''}${src}`)
    const height = 150
    const width = height * (d.width / d.height)
    return {
      id: uuid,
      type: 'image',
      x: 0,
      y: 0,
      z: zLayers.content,
      height: height,
      width: width,
      properties: {
        subtype: 'floralPix',
      },
      image: {
        url: `${srcPrefix || ''}${src}`,
        edits: {},
      },
    }
  }

  const createTextObj = (textValue) => {
    const uuid = uuidv4()
    return {
      id: uuid,
      type: 'text',
      // is the register book the same width? Will the vertical line ever change?
      x: 77.5,
      y: 24,
      z: zLayers.content,
      height: 48,
      width: 325,
      properties: {},
      text: {
        value: `<p data-case-detail=""><span style="font-size: 36pt;">${textValue}</span></p>`,
      },
    }
  }

  const imagePlacement = (centerLine, index) => {
    const topOffset = 112
    const imageSize = 150
    const gutter = 32
    const imageNumber = index + 1
    const isOnTheRight = isEven(imageNumber)

    // handle column offset
    const x = isOnTheRight
      ? centerLine - imageSize * 0.5 + gutter
      : centerLine - imageSize * 1.5 - gutter

    // handle row offset
    let y

    if (imageNumber.toString().match(/[1-2]/)) {
      y = topOffset
    }

    if (imageNumber.toString().match(/[3-4]/)) {
      y = topOffset + gutter + imageSize
    }

    if (imageNumber.toString().match(/[5-6]/)) {
      y = topOffset + gutter * 2 + imageSize * 2
    }

    return [x, y]
  }

  const addPage = useCallback(
    async (arrangements) => {
      //to add a page, we need to send a new page to the db, create a page payload using the blank page, omitting info the db won't take
      const blankPageDetail = _omit(basePages[0], [
        'archived',
        'id',
        'json_template',
      ])
      //give the blank page a new page number (it will be the last page in the set)
      const newBlank = produce({ ...blankPage }, (draft) => {
        _set(draft, 'pageProperties.pageNumber', pages.length + 1)
      })
      //stringify the json to send to the db
      blankPageDetail.json_template = JSON.stringify(newBlank)

      const createRespPage = async (blankPageDetail) => {
        try {
          const { status, data } = await createOrderPage(blankPageDetail)
          if (status === 201 && data) {
            data.json_template.product.orderPageId = data.id
            return data.json_template
          }
        } catch (err) {
          handleError(err?.response?.data?.message || err.message, err)
        }
      }

      const createPageObjectWithItems = async (items) => {
        const respPage = await createRespPage(blankPageDetail)
        items = items.map((item, i) => {
          const [x, y] = imagePlacement(
            basePages[0].json_template.product.alignmentPoints.vertical[0],
            i,
          )
          return {
            ...item,
            x,
            y,
          }
        })
        items.push(createTextObj('Floral Tributes'))
        return {
          ...respPage,
          product: {
            ...respPage.product,
            items,
          },
        }
      }

      //add the new page from the db to the pages array and then update all the page numbers just in case anything changed.
      const updatePageNumbers = (newPages) =>
        newPages.map((page, i) => {
          const newField = produce(page, (draft) => {
            _set(draft, 'pageProperties.pageNumber', i + 1)
          })
          return newField
        })

      // if arrangements is passed, create array of response pages with relevant image items
      if (arrangements && arrangements.length > 0) {
        let respPages = []
        for (let i = 0; i < arrangements.length; i++) {
          const imageItems = await Promise.allSettled(
            arrangements[i].images.map((src) => createImageObj(src)),
          )

          const sanitizedImageItems = imageItems.reduce(
            (result, promiseObj) => {
              if (promiseObj.status === 'fulfilled') {
                result.push(promiseObj.value)
              }

              return result
            },
            [],
          )

          // handle overflow of images on the page. Only 6 are allowed per page
          if (sanitizedImageItems.length > 6) {
            const copy = [...sanitizedImageItems]
            const remainder = copy.length % 6
            const leftOver = copy.splice(5, remainder)

            respPages.push(await createPageObjectWithItems(copy))
            respPages.push(await createPageObjectWithItems(leftOver))
          } else {
            respPages.push(await createPageObjectWithItems(sanitizedImageItems))
          }
          const newPages = updatePageNumbers([...pages, ...respPages])
          setPages(newPages)
          setEditorPages(newPages)
        }
        setToast({ text: `${respPages.length} new pages added!` })
      } else {
        const respPage = await createRespPage(blankPageDetail)
        setToast({ text: 'New page added!' })
        const newPages = updatePageNumbers([...pages, respPage])
        setPages(newPages)
        setEditorPages(newPages)
      }
      setTrigger(Date.now())
    },
    // eslint-disable-next-line
    [basePages, blankPage, pages],
  )

  useEffect(() => {
    pages.length <= 1 ? setPreventDelete(true) : setPreventDelete(false)
  }, [pages])

  const updatePages = useCallback((pages, oldIndex, newIndex) => {
    //use the built in arrayMove function to reorder the pages array
    let newPages = arrayMove(pages, oldIndex, newIndex)

    //update all the page numbers with the new positions
    newPages = newPages.map((page, index) => {
      const newField = produce(page, (draft) => {
        _set(draft, 'pageProperties.pageNumber', index + 1)
      })
      return newField
    })
    //set the pages and editorPages (trigger save)
    setPages(newPages)
    setEditorPages(newPages)
    // eslint-disable-next-line
  }, [])

  const deletePage = useCallback(
    (e, index) => {
      try {
        setIsDeleting(true)
        e.stopPropagation()
        if (selectedPage === index + 1) {
          if (index !== 0) {
            setSelectedPage(index)
          } else {
            setSelectedPage(1)
          }
        }

        //get the orderPageId from the page[i].product
        const {
          product: { orderPageId },
        } = pages[index]
        //send the archive request off to the db (we probably don't need the response here)
        archiveOrderPage(orderPageId)
        //use the built in arrayRemove to remove the page from the pages array
        let newPages = arrayRemove(pages, index)
        //update all the pageNumbers
        newPages = newPages.map((page, i) => {
          const newField = produce(page, (draft) => {
            _set(draft, 'pageProperties.pageNumber', i + 1)
          })
          return newField
        })
        //set the pages and editorPages (trigger save)
        //TODO get rid of this timeout!
        setTimeout(() => {
          setIsDeleting(false)
          setPages(newPages)
          setEditorPages(newPages)
        }, 250)
      } catch (error) {
        handleError(error?.response?.data?.message || error.message, error)
      }
    },
    // eslint-disable-next-line
    [pages, selectedPage],
  )

  const goToPage = useCallback((index) => {
    //this will just set the selectedPage (page being viewed in the editor)
    setSelectedPage(index + 1)
    // eslint-disable-next-line
  }, [])

  return {
    loading,
    disabled,
    preventDelete,
    pages,
    addPage,
    updatePages,
    deletePage,
    goToPage,
    isDeleting,
  }
}
